/**
 * Splits the string into an array of strings for a passed count of characters, taking into account spaces
 *
 * @param {string}          str
 * @param {number}          symbolsCount
 *
 * @example
 * // returns ['qwe', 'asd', 'zxc']
 * splitStringBySymbols('qwe asd zxc', 5)
 * @example
 * // returns ['qweasdzxc', 'qwe']
 * splitStringBySymbols('qweasdzxc qwe', 5)
 * @example
 * // returns ['qwe a', 'zxc']
 * splitStringBySymbols('qwe a zxc', 5)
 *
 * @returns {string[]}
 */
export default function splitStringBySymbols(str, symbolsCount) {
  if(!str) {
    return []
  }
  const result = []
  const splitStr = str.split(' ')
  let temp = ''
  for(let i = 0; i < splitStr.length; i++) {
    if(temp.length + splitStr[i].length <= symbolsCount) {
      temp = temp ? `${temp} ${splitStr[i]}` : splitStr[i]
      continue
    }

    if(temp) {
      result.push(temp)
      temp = splitStr[i]
    } else {
      result.push(splitStr[i])
      temp = ''
    }
  }

  if(temp) {
    result.push(temp)
  }

  return result
}
