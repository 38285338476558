import { useMemo } from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'
import get from 'lodash/get'
import { Col, Row } from 'antd'

import { Collapse, Container, Panel, Typography, BlockWrapper } from 'common/widgets'
import { ImageType } from '../types'
import ExpandIcon from './ExpandIcon'
import classNames from './styles.module.scss'


Accordion.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })),
    image: ImageType,
  }),
  priorityLoad: PropTypes.bool,
}

Accordion.defaultProps = {
  data: {},
  priorityLoad: false,
}

export default function Accordion({ data, priorityLoad }) {
  const items = useMemo(() => data.items.map(({ question, question_hx: questionHx, answer }, idx) => (
    <Panel
      key={idx}
      isClassless
      forceRender
      header={<Typography
        variant="subhead"
        tag={questionHx}
        className={classNames.title}
      >
        {question}
      </Typography>}
      className={classNames.panel}
    >
      <Typography
        color="light-black"
        tag="div"
        dangerouslySetInnerHTML={{ __html: answer }}
        className={classNames.baseStyles}
      />
    </Panel>
  )), [data.items])
  const altText = useMemo(() => get(data, 'image.data.alttext', ''), [data.image])
  const titleText = useMemo(() => get(data, 'image.data.name', ''), [data.image])
  const src = useMemo(() => get(data, 'image.bynder.derivatives.webImage'), [data.image])

  return (
    <BlockWrapper>
      <Container size="xl">
        <Row gutter={[{ lg: 40 }]} align="stretch">
          <Col span={24} md={16}>
            <Collapse accordion expandIcon={ExpandIcon}>
              {items}
            </Collapse>
          </Col>
          <Col
            span={24}
            md={8}
          >
            <Image
              src={src}
              className={classNames.image}
              alt={altText}
              title={titleText}
              layout="fill"
              sizes="33vw"
              priority={priorityLoad}
            />
          </Col>
        </Row>
      </Container>
    </BlockWrapper>
  )
}
