import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import cx from 'classnames'

import DotsList from './DotsList'
import Dot from './Dot'
import Slide from './Slide'
import classNames from './styles.module.scss'
import ImageType from '../types/ImageType'


NewsCarousel.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      image: ImageType,
      tag: PropTypes.string,
      heading: PropTypes.string,
      text: PropTypes.string,
      cta: PropTypes.string,
      cta_url: PropTypes.string,
    })),
    cta_link_caption: PropTypes.string,
  }),
}

NewsCarousel.defaultProps = {
  data: {
    items: [],
  },
  cta_link_caption: '',
}

export default function NewsCarousel({ data }) {
  const [className, setClassName] = useState(classNames.slider)
  const settings = useMemo(() => ({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: DotsList,
    customPaging: Dot,
    pauseOnHover: false,
  }), [])
  const slides = useMemo(() => data.items.map(item => (
    <Slide key={item.heading} caption={data.cta_link_caption} {...item} />
  )), [data.items])

  useEffect(() => {
    setClassName(cx(classNames.slider, classNames.ready))
  }, [])

  return (
    <div>
      <Slider {...settings} className={className}>
        {slides}
      </Slider>
    </div>
  )
}
